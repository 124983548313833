import { red } from "@mui/material/colors";
import { createTheme } from "@mui/material";

export const blackTheme = createTheme({
    palette: {
        primary: {
            main: '#262626'
        },
        secondary: {
            main: '#ffffff'
        },
        error: {
            main: red.A400
        }
    }
})