import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import {Icon} from 'leaflet'
import '../assets/css/mapa.css'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

const icon = new Icon ({
  iconUrl:'/ubi.svg',
  iconSize: [30,30]
})

export const Mapa = () => {
  return (
    <Box>
      <Typography 
        sx={{mt:'.5rem', fontWeight:'bold', fontSize: '20px', textAlign:'center'}}
        >
        Cobertura
      </Typography>

      <MapContainer className='contenedor_mapa' center={[19.999235, -89.039346]} zoom={6.5} >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        <Marker position={[18.597710, -90.742716]}icon={icon} >
          <Popup>Escarcega, Campeche</Popup>
        </Marker>
        <Marker position={[19.852313, -90.531134]} icon={icon}>
          <Popup>Campeche, Campeche</Popup>
        </Marker>
        <Marker position={[19.352492, -90.720108]} icon={icon}>
          <Popup>Champotón, Campeche</Popup>
        </Marker>
        <Marker position={[18.506734, -89.393589]} icon={icon}>
          <Popup>Xpujil, Campeche</Popup>
        </Marker>
        <Marker position={[18.25643140979388, -92.0903608399048]} icon={icon}>
          <Popup>Palizada, Campeche</Popup>
        </Marker>

        <Marker position={[20.967374, -89.592573]} icon={icon}>
          <Popup>Mérida, Yucatán</Popup>
        </Marker>
        <Marker position={[20.887856, -89.748113]} icon={icon}>
          <Popup>Umán, Yucatán</Popup>
        </Marker>
        <Marker position={[21.098186, -89.279974]} icon={icon}>
          <Popup>Motul, Yucatán</Popup>
        </Marker>
        <Marker position={[20.932754, -89.556834]} icon={icon}>
          <Popup>Kanasín, Yucatán</Popup>
        </Marker>
        <Marker position={[20.935043, -89.021875]} icon={icon}>
          <Popup>Izamal, Yucatán</Popup>
        </Marker>
        <Marker position={[20.694524, -88.201222]} icon={icon}>
          <Popup>Valladolid, Yucatán</Popup>
        </Marker>
        <Marker position={[21.150028, -88.148888]} icon={icon}>
          <Popup>Tizimín, Yucatán</Popup>
        </Marker>
        <Marker position={[20.402608, -89.536672]} icon={icon}>
          <Popup>Ticul, Yucatán</Popup>
        </Marker>
        <Marker position={[20.2079583, -89.2867172]} icon={icon}>
          <Popup>Tekax, Yucatán</Popup>
        </Marker>
        <Marker position={[21.284715, -89.661664]} icon={icon}>
          <Popup>Progreso, Yucatán</Popup>
        </Marker>

        <Marker position={[21.183870, -86.849283]} icon={icon}>
          <Popup>Cancún, Quintana Roo</Popup>
        </Marker>
        <Marker position={[18.512341, -88.294491]} icon={icon}>
          <Popup>Chetumal, Quintana Roo</Popup>
        </Marker>
        <Marker position={[20.638956, -87.073082]} icon={icon}>
          <Popup>Playa del Carmen, Quintana Roo</Popup>
        </Marker>
        <Marker position={[21.239343, -86.735718]} icon={icon}>
          <Popup>Isla Mujeres, Quintana Roo</Popup>
        </Marker>
        <Marker position={[20.439293, -86.919723]} icon={icon}>
          <Popup>Cozumel, Quintana Roo</Popup>
        </Marker>
        <Marker position={[21.107373, -87.484768]} icon={icon}>
          <Popup>Kantunilkín, Quintana Roo</Popup>
        </Marker>
        <Marker position={[19.584346, -88.043518]} icon={icon}>
          <Popup>F. Carrillo Puerto, Quintana Roo</Popup>
        </Marker>
        <Marker position={[19.751100, -88.705741]} icon={icon}>
          <Popup>J. María Morelos, Quintana Roo</Popup>
        </Marker>
      </MapContainer>
    </Box>
  )
}
