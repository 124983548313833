import { Button, InputLabel, MenuItem, TextField, Typography, FormHelperText, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { useState } from 'react'
import axios from 'axios'

export const Contacto = ({contacto}) => {

  const DataInicial = {
      name: '',
      email: '',
      phone:'',
      state:'',
      message: ''
  }
  const [errors, setError]=useState({})
  const [loading, setLoading]=useState(false)
  const [alert, setAlert] = useState(false)
  const [form, setForm] = useState(DataInicial);
  
  const {name, email, phone, state, message} = form;
  
  const handleChange = ({target}) => {
      const {name, value} = target;
      setForm({
        ...form,
        [name]: value
      });  
  }
  const onValidate=(form)=>{
    let errors = {}
    let valiteEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
    let valitePhone = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm

    if (!form.name.trim()){
      errors.name = 'El campo NOMBRE no debe estar vacío'
    }

    if (!form.email.trim()){
      errors.email = 'El campo CORREO  vacío'
    } else if(!valiteEmail.test(form.email)){
      errors.email = 'El correo no cuenta con la estructura correcta, (ejemplo@correo.com)'
    }

    if (!form.phone.trim()){
      errors.phone = 'El campo TELEFONO no debe estar vacío'
    }else if(!valitePhone.test(form.phone)){
      errors.phone = 'El número de teléfono no es valido'
    }

    if (!form.state.trim()){
      errors.state = 'El campo ESTADO no debe estar vacío'
    }

    if (!form.message.trim()){
      errors.message = 'El campo MENSAJE no debe estar vacío'
    }
    return errors 
  }

  const handleSubmit = (e)=>{
    e.preventDefault()
    const data = new FormData()
    const err = onValidate(form)
    setError(err)

    console.log(Object.keys(err).length)

    if (Object.keys(err).length === 0){
      setLoading(true)
      data.append ('name', name)
      data.append ('email', email)
      data.append ('phone', phone)
      data.append ('state',state)
      data.append ('message', message)

      axios.post('https://www.gestoriajp.com/mail/contact_me.php', data)
        .then(data =>{
          console.log(data)
          setForm(DataInicial)
          setLoading(false)
          handleClickOpen()
        })
        .catch(error=>{
          console.log(error)
          setLoading(false)
        })
    } 
  }

  const handleClickOpen = () => {
    setAlert(true);
  };

  const handleClose = () => {
    setAlert(false);
  };


  return (

    <Box borderTop="solid 1px rgba(6, 74, 76, .48)" mt="1rem" ref={contacto}>

      <Dialog
        open={alert}
        onClose={handleClose}
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            Mensaje enviado con éxito
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>


      <Typography
        sx={{
          mt: ".5rem",
          fontWeight: "bold",
          fontSize: "25px",
          textAlign: "center",
        }}
      >
        Contacto
      </Typography>
      <Typography>
        No tiene Abogado en la península de Yucatán…no se preocupe ¡Cualquier
        trámite de nuestros servicios desde su hogar u oficina!
      </Typography>

      <Box
        display="grid"
        gridTemplateColumns={{ xs: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
        mt="10px"
      >
        <Box
          p="0px 30px 0 0px"
          textAlign="left"
          borderRight={{ xs: "none", md: "solid 1px rgba(6, 74, 76, .18)" }}
          borderBottom={{ xs: "solid 1px rgba(6, 74, 76, .18)", md: "none" }}
        >
          <ol>
            <li className="espaciado">
              Elige tu trámite con tu Gestor o Abogado Especializado
            </li>
            <li className="espaciado">Realice su pago</li>
            <li className="espaciado">
              Envíe documentos con los insertos necesarios
            </li>
            <li>
              ¡Reciba su información en Línea o correo en cualquier parte de
              México y el Mundo!
            </li>
          </ol>
        </Box>
        <Box
          p="0px 30px 0 30px"
          component="form"
          mt="15px"
          onSubmit={handleSubmit}
        >
          <Box mb="10px">
          <TextField
            onChange={handleChange}
            type="text"
            name="name"
            label="Nombre"
            fullWidth
            variant="outlined"
            size="small"
            value={name}
            helperText={null}
          />
          {errors.name && <FormHelperText sx={{color:'#BD1119'}} >{errors.name}</FormHelperText>}
          </Box>
          <Box mb="10px">
            <TextField
              onChange={handleChange}
              type="text"
              name="email"
              label="Correo"
              fullWidth
              variant="outlined"
              size="small"
              value={email}
            />
            {errors.email && <FormHelperText sx={{color:'#BD1119'}} >{errors.email}</FormHelperText>}
          </Box>
          <Box mb="10px">
            <TextField
              onChange={handleChange}
              type="text"
              name="phone"
              label="Telefono"
              fullWidth
              variant="outlined"
              size="small"
              value={phone}
            />
            {errors.phone && <FormHelperText sx={{color:'#BD1119'}}>{errors.phone}</FormHelperText>}
          </Box>
          <Box mb="10px">
            <InputLabel></InputLabel>
            <TextField
              fullWidth
              select
              name="state"
              value={state}
              label="Estado"
              onChange={handleChange}
            >
              <MenuItem value="Aguascaliente">Aguascalientes</MenuItem>
              <MenuItem value="Baja Califirnia">Baja Califirnia</MenuItem>
              <MenuItem value="Baja California Sur">Baja California Sur</MenuItem>
              <MenuItem value="Campeche">Campeche</MenuItem>
              <MenuItem value="Chiapas">Chiapas</MenuItem>
              <MenuItem value="Chihuahua">Chihuahua</MenuItem>
              <MenuItem value="Ciudad de México">Ciudad de Mexico</MenuItem>
              <MenuItem value="Coahuila">Coahuila</MenuItem>
              <MenuItem value="Colima">Colima</MenuItem>
              <MenuItem value="Durango">Durango</MenuItem>
              <MenuItem value="Estado de México">Estado de México</MenuItem>
              <MenuItem value="Guanajuato">Guanajuato</MenuItem>
              <MenuItem value="Guerrero">Guerrero</MenuItem>
              <MenuItem value="Hidalgo">Hidalgo</MenuItem>
              <MenuItem value="Jalisco">Jalisco</MenuItem>
              <MenuItem value="Michoacán">Michoacán</MenuItem>
              <MenuItem value="Morelos">Morelos</MenuItem>
              <MenuItem value="Nayarit">Nayarit</MenuItem>
              <MenuItem value="Nuevo León">Nuevo León</MenuItem>
              <MenuItem value="Oaxaca">Oaxaca</MenuItem>
              <MenuItem value="Puebla">Puebla</MenuItem>
              <MenuItem value="Querétaro">Querétaro</MenuItem>
              <MenuItem value="Quintana Roo">Quintana Roo</MenuItem>
              <MenuItem value="San Luis Potosí">San Luis Potosí</MenuItem>
              <MenuItem value="Sinaloa">Sinaloa</MenuItem>
              <MenuItem value="Sonora">Sonora</MenuItem>
              <MenuItem value="Tabasco">Tabasco</MenuItem>
              <MenuItem value="Tamaulipas">Tamaulipas</MenuItem>
              <MenuItem value="Tlaxcala">Tlaxcala</MenuItem>
              <MenuItem value="Veracruz">Veracruz</MenuItem>
              <MenuItem value="Yucatán">Yucatán</MenuItem>
              <MenuItem value="Zacatecas">Zacatecas</MenuItem>
            </TextField>
            {errors.state && <FormHelperText sx={{color:'#BD1119'}}>{errors.state}</FormHelperText>}
          </Box>
          <Box mb="10px">
            <TextField
              onChange={handleChange}
              type="text"
              name="message"
              label="Mensaje"
              fullWidth
              variant="outlined"
              multiline
              rows={3}
              value={message}
            />
            {errors.message && <FormHelperText sx={{color:'#BD1119'}}>{errors.message}</FormHelperText>}
          </Box>
          <Typography textAlign="center" fontSize="10px">
            Al enviar el formulario, usted acepta nuestra política de
            privacidad.
          </Typography>
          <Box
            width="100%"
            height="80px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Button
              type="submit"
              name="submit"
              variant="contained"
              sx={{backgroundColor:'#BD1119'}}
              disabled={loading}
            >
              {loading ? "Enviando ..." : "Enviar"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
