import { Grid, IconButton, Typography} from '@mui/material';
import { Box} from '@mui/system'
import React from 'react'
import logo from '../assets/img/logo.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const Footer = () => {
  return (
    <Grid container columns={{ xs: 4, md: 12 }} backgroundColor='#262626' alignItems='center' justifyContent='center' flexDirection={{xs:'column', md:'row'}} mt='20px'>
        <Grid item xs={3} alignItems='center' justifyItems='center'>
            <Box component="img" sx={{height: 60}} alt="Your logo." src={logo} m='0 auto' p='10px' />
        </Grid>
        <Grid item xs={3} justifyContent='space-between'>
              <IconButton href='https://wa.link/9noiuo'>
                <WhatsAppIcon color='secondary'/>
              </IconButton>
              <IconButton href='https://www.facebook.com/gestoriajpmx'>
                <FacebookIcon color='secondary'/>
              </IconButton>
              <IconButton href='https://www.linkedin.com/company/gestoriajpmx'>
                <LinkedInIcon color='secondary'/>
              </IconButton>
            </Grid>
        <Grid item xs={3}>
            <Typography color='#ffffff' >Términos y condiciones</Typography>
        </Grid>
        <Grid item xs={3}>
            <Typography color='#ffffff' >Politicas de privacidad</Typography>
        </Grid>
    </Grid>

  )
}
