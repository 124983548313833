import {ThemeProvider} from '@emotion/react';
import { CssBaseline } from '@mui/material';
import { blackTheme } from './inde';


export const AppTheme = ({children}) => {
  return (
    <ThemeProvider theme={blackTheme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
     {children}
  </ThemeProvider>
  )
}

