import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Mapa } from './Mapa'


export const Inicio = ({inicio}) => {
  return (
    <Box ref={inicio}>
        <Typography 
          sx={{fontWeight:'bold', fontSize: '25px', textAlign:'center'}}
          >
          ¿Quiénes somos?
        </Typography>

        <Typography 
          sx={{mt:'1rem'}}
          textAlign={{xs:'center', md:'justify'}}
          >
          Un Corporativo penínsular independiente, especializados en las diligencias de exhortos, despachos, cartas rogatorias, amparos y expedientes en los Estados de la Península de Yucatán, apostando por una relación estrecha con el cliente, con el propósito de brindarle un servicio seguro y eficiente en la movilidad de su asunto.
        </Typography>
        <Typography mt='10px' >Somos una unidad del GRUPO DLR, especializados en:</Typography>
        <Box display='grid' gridTemplateColumns={{xs:'repeat(1, 1fr)', md:'repeat(2, 1fr)'}}>
          <Box>
            <Box p={{xs:'0 30px 0 0', md:'0px 30px 0 0px'}} textAlign='left' borderRight={{xs:'none', md:'solid 1px rgba(6, 74, 76, .18)'}} borderBottom={{xs:'solid 1px rgba(6, 74, 76, .18)', md:'none'}} >
                <ul className='vineta'>
                  <li className='espaciados'>Diligenciar todo tipo de Exhortos provenientes de cualquier parte de la República Mexicana.</li>
                  <li className='espaciados'>Diligenciar todo tipo de Despachos, Amparos y Demandas.</li>
                  <li className='espaciados'>Diligenciar todo tipo de Carta Rogatoria de cualquier país extranjero.</li>
                  <li className='espaciados'>Recepción de documentos para presentación ante las Autoridades Judiciales o Administrativas.</li>
                </ul>
            </Box>
          </Box>
            <Box p={{xs:'0 30px 0 0', md:'0px 0px 0 30px'}} textAlign='left'  >
                  <ul className='vineta'>
                    <li className='espaciados'>Para oír y recibir todo tipo de notificaciones y documentos de expedientes.</li>
                    <li className='espaciados'>Para oír y recibir todo tipo de notificaciones y documentos de Amparos.</li>
                    <li className='espaciados'>Presentar promociones tendientes al cumplimiento de su asunto.</li>
                  </ul>
              </Box>
        </Box>
              <Mapa/>
        </Box>
  )
}
