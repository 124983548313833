import { Avatar, Card, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import unam from '../assets/img/UNAM.png'
import uady from '../assets/img/UADY.png'
import uqroo from '../assets/img/UQROO.png'


export const Nosotros = ({nosotros}) => {
  return (
    <Box borderTop='solid 1px rgba(6, 74, 76, .48)' mb='1rem' ref={nosotros}>
    <Typography 
      sx={{mt:'.5rem', fontWeight:'bold', fontSize: '25px', textAlign:'center'}}
      >
      Nosotros
    </Typography>
    <Typography>
    Nosotros, somos egresados de:
    </Typography>
    <Box display="grid" gridTemplateColumns={{xs:'repeat(1, 1fr)',sm:'repeat(2, 1fr)', md:'repeat(3, 1fr)'}} gap={1} marginTop='1rem'>

       
        <Card
        sx={{maxWidth:320, minHeight:200, position:'relative', m:'0 auto'}} 
        >
            <Box component='div' className='head' sx={{position:'relative'}}>
                <Box component='div' className='circle'>

                </Box>
                <Box className='img'>
                    <Avatar alt='Licenciado' src={unam} className='avatar'></Avatar>
                </Box>   
            </Box>
            <Box p='20px 20px 20px 20px'>        
                <Typography component='h3' sx={{fontSize:'1.2rem'}}>Universidad Autónoma de México</Typography>
            </Box>
        </Card> 

        <Card
        sx={{maxWidth:320, minHeight:200, position:'relative', m:'0 auto'}} 
        
        >
            <Box component='div' className='head' sx={{position:'relative'}}>
                <Box component='div' className='circle'>

                </Box>
                <Box className='img'>
                    <Avatar alt='Licenciado' src={uady} className='avatar'></Avatar>
                </Box>   
            </Box>
            <Box  p='20px 20px 20px 20px'>
                <Typography component='h3' sx={{fontSize:'1.2rem'}}>Universidad Autónoma de Yucatán </Typography>
            </Box>
        </Card> 
        <Card
        sx={{maxWidth:320, minHeight:200, position:'relative', m:'0 auto' }}
        
        >
            <Box component='div' className='head' sx={{position:'relative'}}>
                <Box component='div' className='circle'>

                </Box>
                <Box className='img'>
                    <Avatar alt='Licenciado' src={uqroo} className='avatar'></Avatar>
                </Box>   
            </Box>
            <Box p='20px 20px 20px 20px'>

                <Typography component='h3' sx={{fontSize:'1.2rem'}}>Universidad Autónoma de Quintana Roo</Typography>

            </Box>
        </Card> 
    </Box>
    <Box display='grid' gridTemplateColumns={{xs:'repeat(1, 1fr)', md:'repeat(2, 1fr)'}} mt='1rem'>
        <Box p={{xs:'0', md:'0px 30px 0 30px'}}>
            <Typography textAlign='center' fontSize='20px' fontWeight='bold' mb='10px'>“Nuestra Visión”</Typography>
            <Typography textAlign='justify'>Ser la primera gestoría judicial en la península de Yucatán reconocida por sus prácticas y diligencias apegadas a la Ley, mediante un equipo corporativo penínsular de abogados profesionales, con amplia experiencia en materia judicial, capaces de brindarle una movilidad de su asunto en forma segura y eficiente.</Typography>
        </Box>
        <Box p={{xs:'0', md:'0px 30px 0 30px'}}>
            <Typography textAlign='center' fontSize='20px' fontWeight='bold' mb='10px'>“Nuestros Valores”</Typography>
            <Grid container >
                <Grid item xs={6} md={8} >
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>RESPONSABILIDAD</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>RESPETO</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                     <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>LEALTAD</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>HONESTIDAD</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>PROFESIONALISMO</Typography>
                    </Box>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>ÉTICA</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Box backgroundColor='#BD1119' borderRadius='10px' p='10px' m={{xs:'5px', md:'10px'}}>
                        <Typography textAlign='center' color='white' fontSize={{xs:'14px', md:'18px'}}>CONFIDENCIALIDAD</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    </Box>
  </Box>
  )
}
