import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import logo from '../assets/img/logo.png';


export const Info = () => {
  return (
    <Box className='info'>
        <Box component='div' className='contenido'>
            <Box component="img" sx={{height: 120}} alt="Your logo." src={logo} />
            <Typography textAlign='center' sx={{color:'#ffffff'}} fontSize={{xs:'30px', md:'48px'}} >Gestoría Judicial Penínsular </Typography>
            <Typography textAlign='center' sx={{color:'#ffffff'}} fontSize={{xs:'15px', md:'20px'}}>“Somos tus abogados para diligenciar tu exhorto”</Typography>
        </Box>
    </Box>
  )
}
