import { Box} from '@mui/system'
import React from 'react'
import { Navbar, Nosotros, Info, Servicios, Inicio, Footer, Contacto} from '../components'
import { useRef } from 'react'



export const GestionPage = () => {
  const inicio = useRef(null);
  const nosotros = useRef(null);
  const servicios = useRef(null);
  const contacto = useRef(null);


  return (
    <>
      <Navbar inicio={inicio} nosotros={nosotros} servicios={servicios} contacto={contacto} />
      <Info/>
      <Box component='main' className='contenedor sombra' >
        <Inicio inicio={inicio} />
        <Nosotros nosotros={nosotros} />
        <Servicios servicios={servicios} />
        <Contacto contacto={contacto} />
      </Box>
      <Footer/>
    </>
      
  )
}
