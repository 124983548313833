import { AppBar, Grid, IconButton, Link, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { MenuOutlined } from '@mui/icons-material';
import logo from '../assets/img/logo.png';
import PropTypes from 'prop-types';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';


export const Navbar = ({inicio, nosotros, servicios, contacto}) => {
    const [anchorEl, setAnchor]= React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (e)=>{
        setAnchor(e.currentTarget);
    }
    const handleClose = () =>{
        setAnchor(null);
    }
    
    const scrollToSection = (element)=>{
        window.scrollTo({
          top: element.current.offsetTop - 70,
          behavior: 'smooth'
        })
      }

  return (
    <AppBar position='fixed'>
        <Toolbar className='toolbar'>
            <IconButton
                color='inherit'
                sx={{mr:2, display: {sm: 'none'}}}
                onClick={handleClick}
            >
                <MenuOutlined/>
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}

            >
                <MenuItem>
                    <Typography onClick={()=>scrollToSection(inicio)}>
                        Inicio
                    </Typography>
                </MenuItem>
                <MenuItem>
                    <Typography onClick={()=>scrollToSection(nosotros)}>
                        Nosotros
                    </Typography>
                </MenuItem>
                <MenuItem>
                    <Typography onClick={()=>scrollToSection(servicios)}>
                        Servicios
                    </Typography>
                </MenuItem>
                <MenuItem>
                    <Typography onClick={()=>scrollToSection(contacto)}>
                        Contacto
                    </Typography>
                </MenuItem>  
            </Menu>
            <Box
                component="img"
                sx={{
                height: 50,
                m:'0 auto'}}
                alt="Your logo."
                src={logo}
               
            />
            <Grid container direction='column' justifyContent='space-between' alignItems='left'>

                <Box sx={{flexGrow: 1, display: {xs:'none',sm:'flex'}, alignItems:'center', gap:'5px', justifyContent:'flex-end'}}>
                    <MailOutlineIcon fontSize='20px' color='secondary'/>
                    <Link href='mailto:contacto@gestoriajp.com' color="inherit" underline='none' fontSize='15px' mr='10px'>
                        contacto@gestoriajp.com
                    </Link>
                    <WhatsAppIcon  fontSize='20px' color='secondary'/>
                    <Link href='https://wa.link/9noiuo' color="inherit" underline='none' fontSize='14px'>
                        (984) 320 02 12
                    </Link>
                    
                </Box>
                
                <Box
                    sx={{flexGrow: 1, display: {xs:'none',sm:'flex'}}}
                    justifyContent='flex-end'
                    
                >
                    
                    <MenuItem>
                              <Typography onClick={()=>scrollToSection(inicio)}>
                                  Inicio
                              </Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography onClick={()=>scrollToSection(nosotros)}>
                            Nosotros
                        </Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography onClick={()=>scrollToSection(servicios)}>
                            Servicios
                        </Typography>
                    </MenuItem>
                    <MenuItem>
                        <Typography onClick={()=>scrollToSection(contacto)}>
                            Contacto
                        </Typography>
                    </MenuItem>
                </Box>
            </Grid>
        </Toolbar>
    </AppBar>
  )
}

Navbar.propTypes={
    inicio: PropTypes.any,
    nosotros: PropTypes.any,
    servicio: PropTypes.any,
    contacto: PropTypes.any

}
