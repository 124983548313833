
import React from 'react'
import { GestionPage } from './pages/GestionPage'
import { AppTheme } from './theme/inde'



export const GestionApp = () => {
  return (
    <AppTheme>
      <GestionPage/>
    </AppTheme>
     
  )
}
