import { Box, Card, Typography } from "@mui/material";
import React from "react";

export const Servicios = ({ servicios }) => {
  return (
    <Box borderTop="solid 1px rgba(6, 74, 76, .48)" ref={servicios}>
      <Typography
        sx={{
          mt: ".5rem",
          fontWeight: "bold",
          fontSize: "25px",
          textAlign: "center",
        }}
      >
        Servicios
      </Typography>
      <Typography>
        Brindamos el mejor asesoramiento profesional jurídico en:
      </Typography>
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(1, 1fr)",
          sm: "repeat(2, 1fr)",
          md: "repeat(4, 1fr)",
        }}
        gap={2}
        marginTop="1rem"
      >
        <Card className="card">
          <Box className="img1 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Familiar
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Divorcios</li>
              <li>Custodia y Alimentos</li>
              <li>Régimen de visitas</li>
              <li>Consignación de pensión alimenticia</li>
              <li>Investigación de paternidad</li>
              <li>Sucesión Testamentaria e Intestamentaria</li>
              <li>Autorización para salir un menor del país</li>
              <li>Homologación de convenio</li>
              <li>Posesión de estado civil</li>
              <li>Suspensión y pérdida de patria potestad</li>
              <li>Controversias de orden familiar</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img2 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Laboral
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Despido injustificado</li>
              <li>Incumplimiento salarial</li>
              <li>Accidente laboral</li>
              <li>Conflictos individuales y colectivos</li>
              <li>Acoso laboral</li>
              <li>Vacaciones</li>
              <li>Condiciones laborales</li>
              <li>Incapacidad parcial y permanente</li>
              <li>Afiliación al Seguro Social</li>
              <li>Clasificación de categoría profesional</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img3 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Amparo
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Directo</li>
              <li>Indirecto</li>
              <li>Violación a las garantías individuales</li>
              <li>
                Protección contra actos de autoridad que vulneren los derechos
                fundamentales de las personas
              </li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img4 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Convenio y contratos
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Arrendamiento</li>
              <li>Cesión de derechos</li>
              <li>Compraventa</li>
              <li>Comodato</li>
              <li>Adhesión</li>
              <li>Comisión</li>
              <li>Confidencialidad</li>
              <li>Hospedaje</li>
              <li>Mantenimiento</li>
              <li>Prestación de servicios</li>
              <li>Usufructo</li>
              <li>Régimen condominio</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img5 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Civil
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Consignación de pago</li>
              <li>Prescripción positiva</li>
              <li>Desahucio</li>
              <li>Reivindicatorio</li>
              <li>Interpelación judicial</li>
              <li>Medios preparatorios a juicio</li>
              <li>Cumplimento de convenio</li>
              <li>Incumplimiento de contratos</li>
              <li>Responsabilidad civil</li>
              <li>Rescisión de contrato</li>
              <li>Nulidades</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img6 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Mercantil
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Ejecutivo Mercantil</li>
              <li>Interpelación Judicial</li>
              <li>Medios Preparatorios</li>
              <li>Ordinario Mercantil</li>
              <li>Ordinario Oral Mercantil</li>
              <li>Providencias Precautorias</li>
              <li>Pagarés</li>
              <li>Facturas</li>
              <li>Cheques</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img7 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Administrativo estatal y municipal
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Recursos</li>
              <li>Nulidades</li>
              <li>Conflictos administrativos</li>
              <li>Otros</li>
            </ul>
          </Box>
        </Card>

        <Card className="card">
          <Box className="img8 face front">
            <Box className="contenido">
              <Typography
                component="p"
                sx={{
                  fontSize: "1.2rem",
                  margin: "0 auto",
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                Traducción de documentos legales
              </Typography>
            </Box>
          </Box>
          <Box className=" face back">
            <ul>
              <li>Traducción de actas y certificaciones</li>
              <li>Documentos oficiales</li>
              <li>Documentos judiciales</li>
              <li>Documentos privados</li>
              <li>Testamentos</li>
              <li>Demandas</li>
              <li>Apostilla</li>
            </ul>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};
